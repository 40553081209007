import { postRequest, getRequest } from './index'
import { PIAOJUURL } from '@/systemConfiguration/index'
// 查询客户分页列表
export const customerListPage = (data, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + 'customer/listPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询票据分页列表
export const manageListPage = (data, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + 'manage/listPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询票据详情
export const operateGetBillDesc = (id, successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getBillDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询订单分页列表
export const orderListPage = (data, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + 'order/listPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询订单详情
export const operateGetBillOrderDesc = (id, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + `getBillOrderDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询票面金额
export const getBillAmount = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getBillAmount`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询订单笔数
export const getBillOrderQuantity = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getBillOrderQuantity`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询购买金额
export const getBuyBillAmount = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getBuyBillAmount`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询发放收益
export const getProvideIncomeAmount = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getProvideIncomeAmount`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询客户购买金额排名
export const getClientBuyAmountRank = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getClientBuyAmountRank`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询客户领取收益排名
export const getClientIncomeAmountRank = (successCallback, failureCallback) => {
  getRequest(PIAOJUURL + `getClientIncomeAmountRank`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询购买金额趋势
export const getBuyAmountChart = (type) => {
  return new Promise((resolve, reject) => {
    getRequest(PIAOJUURL + `getBuyAmountChart/${type}`, {}, res => (
      resolve(res)
    ), error => {
      reject(error)
    })
  })
}

// 查询发放收益趋势
export const getIncomeAmountChart = (type) => {
  return new Promise((resolve, reject) => {
    getRequest(PIAOJUURL + `getIncomeAmountChart/${type}`, {}, res => (
      resolve(res)
    ), error => {
      reject(error)
    })
  })
}

// 查询渠道信息分页列表
export const channelListPage = (data, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + 'channel/listPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 查询渠道详情
export const queryBillChannelDesc = (id, successCallback, failureCallback) => {
  postRequest(PIAOJUURL + `queryBillChannelDesc/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
