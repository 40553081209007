<template>
  <!-- 客户管理 -->
  <div>
    <!-- 搜索框开始 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <el-form-item label="所在地区" label-width="100px">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formInline.placeArr"
          placeholder="请选择"
          :options="areaOptions"
          :props="areaCascadeProps"
          clearable
          @change="areaCategoryChange"
        />
      </el-form-item>
      <el-form-item
        label="所属企业"
        prop="cmpCode"
      >
        <el-select
          v-model="formInline.cmpCode"
          class="cmpNameSearch"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键字搜索"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in cmpNameArr"
            :key="item.cmpCode"
            :label="item.cmpName"
            :value="item.cmpCode"
          />
        </el-select>
      </el-form-item>
    </FormSearch>
    <!-- 搜索框结束 -->
    <!-- 列表开始 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
      >
        <template #cardNoHeader>
          <div class="cardno-header">
            <span>身份证号</span>
            <span @click="changeCardNoEye">
              <img v-if="cardNoEye" :src="$localPicture + 'piaojuImg/eye_open.png'" alt="">
              <img v-else :src="$localPicture + 'piaojuImg/eye_close.png'" alt="">
            </span>
          </div>
        </template>
      </Table>
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 列表结束 -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Pagination from '@/components/Pagination2.vue'
import Table from '@/components/Table.vue'
import { customerListPage } from '@/api/piaoju.js'
import { getSelectAreaTree } from '@/api/login.js'
import { plusXing } from '@/utils/util.js'
import { getCmpBaseinfoList } from '@/api/memberManagement'

export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      // 划级联渲染规则
      areaCascadeProps: {
        value: 'name',
        label: 'name'
      },
      formInline: {
        pageSize: 10,
        pageNum: 1,
        placeArr: []
      },
      areaOptions: [],
      searchFormItemArr: [
        { type: 'input', label: '客户姓名', value: 'userName' },
        { type: 'input', label: '客户电话', value: 'phoneNo' }
      ],
      itemData: [
        { label: '客户姓名', prop: 'userName', width: 160 },
        { label: '客户电话', prop: 'phoneNo', width: 160 },
        { label: '身份证号', prop: 'cardNo', width: 160, header: 'cardNoHeader' },
        { label: '性别', prop: 'sex', width: 160, child: this.$store.getters.getDictionaryItem('SEX') },
        { label: '所在地区', prop: 'placeArr', width: 160 },
        { label: '所属企业', prop: 'cmpName', width: 140 },
        { label: '创建时间', prop: 'createTime', width: 160 },
        { label: '创建人', prop: 'createUser' }
      ],
      listData: [],
      total: 0,
      cardNoEye: false,
      customerDialogItem: [],
      cmpNameArr: []
    }
  },
  mounted() {
    // 获取地区
    const filterLevel = (list, level = 1) => list.map(v => ({ ...v, children: level <= 1 ? null : filterLevel(v.children, level - 1) }))
    getSelectAreaTree('', (res) => {
      this.areaOptions = filterLevel(res.data, 2)
    })
    this.remoteMethod('')
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      // 不要文件格式的
      customerListPage(this.formInline, (res) => {
        if (res.data.records) {
          res.data.records.forEach((item) => {
            item.placeArr = item.placeProvince + '/' + item.placeCity
            item.cardNoPlain = item.cardNo
            item.cardNo = (plusXing(item.cardNo, 3, 1))
          })
          this.listData = [...res.data.records]
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 搜索栏 级联获取数据
    areaCategoryChange(val) {
      this.formInline.placeProvince = val[0]
      this.formInline.placeCity = val[1]
    },
    // 切换身份号显隐
    changeCardNoEye() {
      this.cardNoEye = !this.cardNoEye
      if (!this.cardNoEye) return this.listData.forEach((item) => { item.cardNo = (plusXing(item.cardNo, 3, 1)) })
      this.listData.forEach((item) => { item.cardNo = item.cardNoPlain })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.cardno-header {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }
}
</style>

